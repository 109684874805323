import { ACTIONS } from "../../action-constants/Actions"


const QuestionFetching=()=>{
    return {
        type: ACTIONS.QUESTION_ACTION.GET_QUESTION.QUESTION_FETCHING,
        payload:{
            isQuestionFetching:true,
        },
        isSuccess: false,
    }
}
const QuestionFetchingFailed=({ result, msg })=>{
    return {
        type: ACTIONS.QUESTION_ACTION.GET_QUESTION.QUESTION_FETCHING_FAILED,
        payload:{
            isQuestionFetching:false,
            result,
            msg
        },
        isSuccess: false,
    }
}
const QuestionFetched=({ result })=>{
    return {
        type: ACTIONS.QUESTION_ACTION.GET_QUESTION.QUESTION_FETCHED,
        payload:{
            isQuestionFetching:false,
            result:result,
        },
        isSuccess: true,
    }
}
export const Question = {
    QuestionFetching,
    QuestionFetchingFailed,
    QuestionFetched
}