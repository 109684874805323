import { ACTIONS } from "../../action-constants/Actions"


const SignIn=()=>{
    return {
        type: ACTIONS.AUTH_ACTION.SIGNIN.SIGNIN,
        payload:{
            isSignIn:true,
        },
        isSuccess: false,
    }
}
const SignInFailed=({ result, msg })=>{
    return {
        type: ACTIONS.AUTH_ACTION.SIGNIN.SIGNIN_FAILED,
        payload:{
            isSignIn:false,
            result,
            msg
        },
        isSuccess: false,
    }
}
const SignedIn=({ result })=>{
    return {
        type: ACTIONS.AUTH_ACTION.SIGNIN.SIGNEDIN,
        payload:{
            isSignIn:false,
            result:result,
        },
        isSuccess: true,
    }
}
export const Auth = {
    SignIn,
    SignInFailed,
    SignedIn
}