import {Button,Modal } from "react-bootstrap";
import React,{  useState } from "react";

const LessonModal = (props)=> {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
         
        <Modal.Body>
        <h5 className="text-success text-center">
          Please click NEXT to move to the next topic 

          or click CONTINUE to stay on this page
        </h5>
          
       
          
          <div className="text-center">
            <Button className="btn btn-modal" onClick={props.continue}>Continue</Button>&nbsp;
            {
                props.isLesson==1?(
                    <Button className="btn btn-modal" onClick={props.startest}>Start Test</Button>
                    
                ):(
                    <Button className="btn btn-modal" onClick={props.next}>Next</Button>
                )
            }
            
          </div>
        </Modal.Body>
         
      </Modal>
    );
  }

  export default LessonModal;