import { VALIDATORS } from "./validation-schemas";

export const COMMON_FUNCTION={
    imagePreview:(event)=>{
        if(event.target.files.length > 0){
          let file = event.target.files[0];
          if(VALIDATORS.isImage(file.name)){
            let preview = URL.createObjectURL(event.target.files[0])
            return {file: Object.assign(preview, file),s3file : file}
          }else{
            return {file: "",s3file : ""}
          }
        }
      },
      YEARS:()=>{
        let from = 2020;
        let end = 10;
        var arr = []
        for(var i=0; i<end; i++){
            arr.push({text: (parseInt(from)+i), value: (parseInt(from)+i), key: i})
        }
         return arr
         
      },
      MONTH:()=>{
        var arr = []
        for(var i=0; i<13; i++){
            arr.push({text: i, value: i, key: i})
        }
        return arr
      },
      handleScrollToStats : () => {
        window.scrollTo({
            top: 0
        })
   },
   timeTOMili:(time)=>{
      let tarr = time.split(':');
      return (tarr[0]*60*60*1000)+(tarr[1]*60*1000)+(tarr[2]*1000)
   }
     
}
