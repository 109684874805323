import { _callApi } from "../../../services/baseService";
import { END_POINT } from "../../../constants/apiEndPoints";
import { Seminar } from "../../actions/SeminarAction/GetSeminarPayment";

export const SeminarPaymentApi = form => {
  return (dispatch, getState) => {
    dispatch(Seminar.SeminarFetching());
    return _callApi(
      END_POINT.SEMINAR.GET_SEMINAR.END_POINT,
      END_POINT.SEMINAR.GET_SEMINAR.METHOD,
      form
    ).then(res => {
      const { message = null, result = null } = res.data;
      if ( Object.keys(result).length === 0) {
        dispatch(Seminar.SeminarFetchingFailed({result,message}));
        return res.data;
      } else {
        dispatch(Seminar.SeminarFetched({result}));
        return res.data;
      }
    });
  };
};
