import React, { Component } from 'react'
// import { Accordion, Icon } from 'semantic-ui-react'
import {Nav, Accordion,Card } from "react-bootstrap";
import { connect } from "react-redux";
import { CONSTANTS } from "../../../../constants/constants";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { withRouter } from 'react-router';


const mapDispatchToProps = dispatch => {
  return {
   
  };
};

const mapStateToProps = state => {
  return {
    contentState:state.content
  };
};

class CourseIndex extends Component {
  state = { activeIndex: 0 }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  render() {
    const { activeIndex } = this.state
    let seminar = this.props.match.params.id
    let content = CONSTANTS.SEMINAR["seminar-1"]
    let semarr = {"seminar-1":1,"seminar-2":2,"seminar-3":3}
    let {list}=this.props.contentState
    let idAct = list.find(ans => (ans.seminarId === seminar && ans.isRead === true))
    return (
      <div className="sidebar-index">
          <div className="div-head">
            <span className="span-head">Driving School</span>
          </div>
        <Nav variant="pills" className="flex-column">
            {
              
              list.map((item,index)=>(
                <>
                {(index==0 && seminar=='seminar-3')?(
                <li className="text-bold"><span>Section-1</span></li>
                ):(
                  (index==8 && seminar=='seminar-3')?(
                    <li className="text-bold"><span>Section-2</span></li>
                    ):(
                      ""
                    )
                )}
                
                  
                 
                <Nav.Item >
                  <Nav.Link disabled = {(idAct && item.id==idAct.id)?false:item.isRead} padding="0" eventKey={item.index} onClick={()=>{
                    this.props.parentCallback(item.index);
                  }} ><span  >{semarr[this.props.match.params.id]}.{index}. &nbsp;</span> {item.index}</Nav.Link>
                </Nav.Item>
                </>
              ))
            }
        </Nav>
      </div>
    )
  }
}

export default CourseIndex  = withRouter(connect(mapStateToProps, mapDispatchToProps)(CourseIndex));
