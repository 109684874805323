import React, { Component } from "react";
import { Switch, Route,Link} from "react-router-dom";
import BillingInformation from '../../authComponents/billingInformtion/BillingInformation'
import { VALIDATORS } from "../../../../constants/validation-schemas";
import { Formik } from "formik";
import {InputBox,SelectBox,LoadingComponent,MaterialUIPickers} from '../../commonComponents/index'
import { CONSTANTS } from "../../../../constants/constants";
import { connect } from "react-redux";
import { SeminarPaymentApi } from "../../../../store/api-actions/seminarPayment/seminarPayment";


const mapDispatchToProps = dispatch => {
    return {
        SeminarPayment: form => dispatch(SeminarPaymentApi(form))
    };
  };
  
  const mapStateToProps = state => {
    return {
        seminarState:state.seminar
    };
  };

class Seminar extends Component {

    constructor(props) {
        super(props);
        this.state = {
          isLoading:false,
          isWarningConfirMsg:false,
          isSuccessConfirMsg:false,
          isFailedConfirMsg:false,
          _id:"",
          message: null,
          title:"",
          formFields: {},
          errors: {},
          messageElement: false,
          success: false,
          file:"",
          s3file:"",
          isLoader:false,
          image:"",
          fromDate:new Date(),
          isSameAddress:false
        };
      }

      componentDidMount(){
        this.props.SeminarPayment().then((result)=>{
          // console.log("=====]]]",list)
                // list.find(ans => (ans.seminarId === "seminar-1" && ans.isPass === true)
        })
      }

    render() {
        let {list}=this.props.seminarState
        
        return (
            <div className="">
                    <div className={list.find(ans => (ans.seminarId === "seminar-1" && ans.isPass === true))?"center-div form-group max-auto complete-lesson":"center-div form-group max-auto"}>
                        <Link to={list.find(ans => (ans.seminarId === "seminar-1" && ans.isPass === true))?
                        "/portal/dashboard/certificate/seminar-1":(list.find(ans => (ans.seminarId === "seminar-1"))?"/portal/seminar/seminar-1":"/portal/dashboard/pay/seminar-1")}>SEMINAR1</Link>
                    </div>
                   <div className={list.find(ans => (ans.seminarId === "seminar-2" && ans.isPass === true))?"center-div form-group max-auto complete-lesson":"center-div form-group max-auto"}>
                        <Link to={list.find(ans => (ans.seminarId === "seminar-2" && ans.isPass === true))?
                        "/portal/dashboard/certificate/seminar-2":(list.find(ans => (ans.seminarId === "seminar-2"))?"/portal/seminar/seminar-2":"/portal/dashboard/pay/seminar-2")}>SEMINAR2</Link>
                    </div>
                    <div className={list.find(ans => (ans.seminarId === "seminar-3" && ans.isPass === true))?"center-div form-group max-auto complete-lesson":"center-div form-group max-auto"}>
                        <Link to={list.find(ans => (ans.seminarId === "seminar-3" && ans.isPass === true))?
                        "/portal/dashboard/certificate/seminar-3":(list.find(ans => (ans.seminarId === "seminar-3"))?"/portal/seminar/seminar-3":"/portal/dashboard/pay/seminar-3")}>SEMINAR3</Link>
                    </div> 
            </div>
        );
    }
}

export default Seminar  = connect(mapStateToProps, mapDispatchToProps)(Seminar);
