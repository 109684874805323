export const END_POINT = {

    //S3 bucket base url
    S3BASE_URL:"https://deelzat-s3.s3.amazonaws.com/",
    // IMG_URL:"http://localhost/dmv/web/src/assets/images/course/",
    // APP_BASE:"http://localhost:3000/DSAC",
    // method 1 is for POST and 2 is for GET and 3 is for put and 4 is for delete

    // BASE_URL:"http://localhost:8009/deelzat/api/v1/deelzat/",//'http://localhost:8009/deelzat/api/v1/admin/',
    BASE_URL:"https://drivinginstructorlicensecourse.com:8009/deelzat/api/v1/deelzat/",
    APP_BASE:"https://drivinginstructorlicensecourse.com/DSAC",
    IMG_BASE:"https://drivinginstructorlicensecourse.com/backend/uploads/", 

    LIMIT:10,
    PAGENUMBER:1,
    CATEGORY:{
        CATEGORIES:{
            END_POINT:"categoryList",
            METHOD:2
        },
        ADDCATEGORY:{
            END_POINT:"addCategory",
            METHOD:1
        },
        EDITCATEGORY:{
            END_POINT:"editCategory",
            METHOD:3
        },
        CATEGORYDETAILS:{
            END_POINT:"categoryDetails",
            METHOD:2
        },
        CATEGORYDELETE:{
            END_POINT:"deleteCategory",
            METHOD:4
        }
    },
    BANNER:{
        GETBANNER:{
            END_POINT:"slider",
            METHOD:2
        }
    },
    PRODUCT:{
        GETPRODUCT:{
            END_POINT:"productList",
            METHOD:2
        },
        GETPRODUCT_DETAILS:{
            END_POINT:"productDetails",
            METHOD:2
        },
        ADDTOCART:{
            END_POINT:"addToCart",
            METHOD:1
        },
        ADDPRODUCT:{
            END_POINT:"addProduct",
            METHOD:1
        },
        GETCARTLIST:{
            END_POINT:"cartList",
            METHOD:2
        },
        DELETECARTPRODUCT:{
            END_POINT:"removeFromCart",
            METHOD:4
        },
        ADDREVIEW:{
            END_POINT:"addReviewRating",
            METHOD:1
        },
        CONFIRMORDER:{
            END_POINT:"confirmOrder",
            METHOD:3
        },
        UPDATECART:{
            END_POINT:"updateCart",
            METHOD:3
        },
        UPDATEBOOKING:{
            END_POINT:"updateBookingStatus",
            METHOD:3
        }
    },
    PRODUCT_REVIEW:{
        GETREVIEW:{
            END_POINT:"reviewList",
            METHOD:2
        }
    },
    WISHLIST:{
        ADDTOWISHLIST:{
            END_POINT:"addToWishList",
            METHOD:3
        }
    },
    AUTH:{
        SIGNUP:{
            END_POINT:"signup",
            METHOD:1
        },
        VERIFYOTP:{
            END_POINT:"verifyOtp",
            METHOD:1
        },
        EDITPROFILE:{
            END_POINT:"editProfile",
            METHOD:1
        },
        MYPROFILE:{
            END_POINT:"myProfile",
            METHOD:2
        },
        SIGNIN:{
            END_POINT:"login",
            METHOD:1
        },
        FORGETPASSWORD:{
            END_POINT:"forgetPassword",
            METHOD:1
        },
        CHANGEPASSWORD:{
            END_POINT:"changePassword",
            METHOD:1
        },
        UPDATEEMAILCONTACT:{
            END_POINT:"updateEmailContact",
            METHOD:3
        },
        SENDOTP:{
            END_POINT:"sendOtp",
            METHOD:3
        },
        CONTACTUS:{
            END_POINT:"contactUs",
            METHOD:1
        }
    },
    PAYMENT:{
        MAKECHARGE:{
            END_POINT:"payment",
            METHOD:1
        }
    },
    QUESTION:{
        GET_QUESTION:{
            END_POINT:"questionList",
            METHOD:2
        }
    },
    CONTENT:{
        GET_CONTENT:{
            END_POINT:"GetContent",
            METHOD:2
        }
    },
    SEMINAR:{
        GET_SEMINAR:{
            END_POINT:"getSeminarPayment",
            METHOD:2
        }
    },
    ANSWER:{
        SAVE_ANSWER:{
            END_POINT:"saveAnswer",
            METHOD:1
        }
    },
    LESSON:{
        LESSON_TIME:{
            END_POINT:"lessonRead",
            METHOD:1
        }
    }              
}