import { ACTIONS } from "../../action-constants/Actions";

export const questionReducer = ( state={}, action={} )=>{

    switch(action.type){
        case ACTIONS.QUESTION_ACTION.GET_QUESTION.QUESTION_FETCHING:{
            let newState = { ...{}, ...state };
            newState.list=[];
            newState.totalCount=0
            newState.filter={}
            return newState
        }
        case ACTIONS.QUESTION_ACTION.GET_QUESTION.QUESTION_FETCHED:{
            let newState = { ...{}, ...state };
            let { result, message } = action.payload;
            newState.list =  result.data?result.data:[];
            newState.totalCount = result.count?result.count:0;
            newState.filter = {}
            return newState;
        }
        case ACTIONS.QUESTION_ACTION.GET_QUESTION.QUESTION_FETCHING_FAILED:{
            let newState = { ...{}, ...state };
            let { error } = action.payload;
            newState.list=[];
            newState.totalCount=0
            newState.filter={}
            return newState
        }
        default:{
            return state;
        }
    }
}