import {Button,Modal } from "react-bootstrap";
import React,{  useState } from "react";

const MyVerticallyCenteredModal = (props)=> {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
         
        <Modal.Body>
        {props.isPass?(<
            h5 className="text-success text-center">Congratulations, you passed!!
        .</h5>
        ):(
            <h5 className="text-danger text-center">Ooo'p you did not pass.</h5>
        )}
          
          
          <p className="text-center">
            Your score is {(props.score).toFixed(2)}% ({props.questionCorrect}/{props.totalQuestion}) 
          </p>
          
          <div className="text-center">
              {props.isPass?(
                  <Button onClick={props.continue}>Continue</Button>
              ):(
                  <>
                    <Button onClick={props.tryAgain}>Try Again</Button>&nbsp;
                    <Button onClick={props.backToLesson}>Back TO Lesson</Button>
                  </>
              )}
          
          
          </div>
        </Modal.Body>
         
      </Modal>
    );
  }

  export default MyVerticallyCenteredModal;