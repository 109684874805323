import { ACTIONS } from "../../action-constants/Actions"


const UserDetailsFetching=()=>{
    return {
        type: ACTIONS.AUTH_ACTION.USER_DEATAILS_FETCH.USER_DEATAILS_FETCHING,
        payload:{
            isUserDetailsFetching:true,
        },
        isSuccess: false,
    }
}
const UserDetailsFetchingFailed=({ result, msg })=>{
    return {
        type: ACTIONS.AUTH_ACTION.USER_DEATAILS_FETCH.USER_DEATAILS_FETCHING_FAILED,
        payload:{
            isUserDetailsFetching:false,
            result,
            msg
        },
        isSuccess: false,
    }
}
const UserDetailsFetched=({ result })=>{
    return {
        type: ACTIONS.AUTH_ACTION.USER_DEATAILS_FETCH.USER_DEATAILS_FETCHED,
        payload:{
            isUserDetailsFetching:false,
            result:result,
        },
        isSuccess: true,
    }
}
export const UserDetails = {
    UserDetailsFetching,
    UserDetailsFetchingFailed,
    UserDetailsFetched
}