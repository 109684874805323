import React, { Component } from "react";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { VALIDATORS } from "../../../../constants/validation-schemas";
import {InputBox,LoadingComponent} from '../../commonComponents/index'
import { ForgetApi } from "../../../../store/api-actions/auth/forgetPasswordApi";
import {MESSAGE} from '../../../../constants/confirmMessage';
import {swal} from '../../../../constants/confirm-alert';

const mapDispatchToProps = dispatch => {
  return {
    forgetPassword: form => dispatch(ForgetApi(form))
  };
};

const mapStateToProps = state => {
  return {
      
  };
};
class ForgotPassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formFields: {},
      errors: {},
      message: null,
      messageElement: false,
      success: false,
      file:"",
      s3file:"",
      isSuccessConfirMsg:false,
      isLoader:false
    };
  }

  handleForget = (values, formikBag)=> {
    this.setState({isLoading:true});
    this.props.forgetPassword(values).then(res=>{
      console.log("result",res)
      if(res.status){
        console.log("result",res)
        // let { history } = this.props
        // localStorage.setItem('dmvUser', JSON.stringify(res.result))
        // this.setState({isLoading:false});
        this.setState({isSuccessConfirMsg:true,isLoading:false,message:"Password send to your email address please check.",title:"Password Reset Successfully"});
      }else{
          this.setState({isFailedConfirMsg:true,isLoading:false,message:res.message,title:MESSAGE.FAILED_TITLE});
      }
    })
  }

  render() {
      var loader = ""
      var conm = ""
       

      var obj = {
        title:this.state.title,
        onConfirm:()=>{
        this.setState({isFailedConfirMsg:false,isSuccessConfirMsg:false});
        this.props.history.push(`/auth/login`)
        },
        subtitle:this.state.message
      }

      if(this.state.isFailedConfirMsg){
          conm = swal.failed(obj);
      }
      
      if(this.state.isSuccessConfirMsg){
          conm = swal.success(obj);
      }
      if(this.state.isLoading){
          loader = <LoadingComponent/>
      }

      return (            
        <>
        {loader}
        {conm}
        <Formik
              initialValues={{
                userName:'',
              }}
              onSubmit={this.handleForget}
              validationSchema={VALIDATORS.FORGET}
              render={({ handleSubmit, errors, touched, values, handleChange,setFieldValue })=>(
                <form onSubmit={handleSubmit}>
                    <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <h3 class="register-heading">Forget Password</h3>
                                <div class="row register-form justify-content-center">
                                        <InputBox
                                            formWrapClass = "form-group col-md-8 max-auto"
                                            inputClass="form-control"
                                            type="text"
                                            placeholder="* User Name"
                                            name = "userName"
                                            handleChange={handleChange}
                                            value={values.userName}
                                            errorClass = "text-danger"
                                            errorText = { touched.userName && errors.userName }
                                        />  
                                        
                                        <div className="col-md-8 max-auto">
                                            <Link className="btn btn-link" to="/auth/customer-information/">Sign Up</Link>
                                            <button type="submit" className="btnRegister" >Submit</button>
                                        </div> 
                                     
                                </div>
                            </div>
                            
                        </div>
                </form>
                )}
                />
    </>  
      );
  }
}
export default ForgotPassword  = connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
