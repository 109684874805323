import React, { Component } from "react";
import { Switch, Route,Link} from "react-router-dom";
import BillingInformation from '../../authComponents/billingInformtion/BillingInformation'
import { VALIDATORS } from "../../../../constants/validation-schemas";
import { Formik } from "formik";
import {InputBox,SelectBox,LoadingComponent,MaterialUIPickers} from '../../commonComponents/index'
import { CONSTANTS } from "../../../../constants/constants";
import { END_POINT } from "../../../../constants/apiEndPoints";
import { connect } from "react-redux";
import { SeminarPaymentApi } from "../../../../store/api-actions/seminarPayment/seminarPayment";
import Doc from '../../../../constants/DocService';
import PdfContainer from '../PdfContainer/PdfContainer';
import certificate from '../../../../assets/certificate/certificate.jpg';
import moment from 'moment';

const mapDispatchToProps = dispatch => {
    return {
        SeminarPayment: form => dispatch(SeminarPaymentApi(form))
    };
  };
  
  const mapStateToProps = state => {
    return {
        seminarState:state.seminar
    };
  };

class Certificate extends Component {

    constructor(props) {
        super(props);
        this.state = {
          isLoading:false,
          isWarningConfirMsg:false,
          isSuccessConfirMsg:false,
          isFailedConfirMsg:false,
          _id:"",
          message: null,
          title:"",
          formFields: {},
          errors: {},
          messageElement: false,
          success: false,
          file:"",
          s3file:"",
          isLoader:false,
          image:"",
          fromDate:new Date(),
          isSameAddress:false
        };
      }

      componentDidMount(){
        this.props.SeminarPayment().then((result)=>{
                let {list}=this.props.seminarState
                // console.log("=====]]]",list)
                // list.find(ans => (ans.seminarId === "seminar-1" && ans.isPass === true)
                console.log("this.props.match.params.id",list.find(ans => (ans.seminarId === this.props.match.params.id && ans.isPass === true)))
                if(!list.find(ans => (ans.seminarId === this.props.match.params.id && ans.isPass === true))){
                  this.props.history.push('/portal/dashboard/');
                }
        })
      }
      createPdf = (html) => Doc.createPdf(html);

    render() {
        let {list}=this.props.seminarState
        let answer = list.length?list[0].answer:null;
        let data = moment((answer?answer[0].created:null)).format('MM/DD/YYYY'); 

        var AuthDetails=JSON.parse(localStorage.getItem("dmvUser"))
        return (
        
        <PdfContainer createPdf={this.createPdf}>
            <div  style={{position:"relative",width:"100%",float:"left"}} >
              <img src={certificate} style={{width:"100%"}} />   
              <div style={CONSTANTS.MAINDIV}>
                <div style={CONSTANTS.NAMEDIV}> {AuthDetails.firstName+' '+AuthDetails.middleName+' '+AuthDetails.lastName}</div>
                <div style={CONSTANTS.CONTENTDIV}>
                  <p style={CONSTANTS.PARA}> For The Six-Hour Online Continuing Education {this.props.match.params.id} for Driving School Instructor and/or Operators  </p>
                  <p style={CONSTANTS.PARA2}>I certify that I Completed the seminar as show above.</p>
                  <p style={CONSTANTS.PARA2}>I certify under penalty of perjury that the foregoing is true and correct..</p>
                </div>
                <div style={CONSTANTS.schoolname}>
                  <span>School Name: {AuthDetails.schoolName} &nbsp;&nbsp;&nbsp;</span>
                  <span> Occupation License Number : {AuthDetails.instructorLicense} &nbsp;&nbsp;&nbsp;  </span>
        <span> Completion Date: {data}</span>
                </div>
                <div style={CONSTANTS.signature}>
                  <span>Signature of Student:_____________</span>
                </div>
              </div>
               
            </div>
        </PdfContainer>
     
        );
    }
}

export default Certificate  = connect(mapStateToProps, mapDispatchToProps)(Certificate);
