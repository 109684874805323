import axios from "axios";
import { END_POINT } from "../constants/apiEndPoints";

export const _callApi = (url, type, data) => {
  let user = null;
  if (localStorage.getItem("dmvUser")) {
    user = JSON.parse(localStorage.getItem("dmvUser"));
  }

  if (data && data.accessToken) {
    user={}
    user.accessToken = data.accessToken;
  }
  // set header conditionally in the api axios method 
  let header = user
    ? {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.accessToken,
        accessToken: user.accessToken
      }
    : { "Content-Type": "application/json" };

  axios.create({
    baseURL: END_POINT.BASE_URL,
    responseType: "json"
  });

  if (type === 2) {
     console.log("header", data);
    if(data && data.pageNo){
      data.limit=END_POINT.LIMIT
    }else if(data){
      data.limit = END_POINT.LIMIT;
      data.pageNo = END_POINT.PAGENUMBER
    }else{
       var data ={limit:END_POINT.LIMIT,pageNo:END_POINT.PAGENUMBER}
    }

    return axios
      .get(`${END_POINT.BASE_URL}${url}`,
      {
        params:data,
        headers: header
      })
      .then(res => {
        console.log("@@@@@@@@&&&&&&&&&",res)
        if(res.data.message=="Unauthorized access "){
          console.log("*******&&&&&&&&&")
          window.location = END_POINT.APP_BASE;
          localStorage.removeItem("dmvUser");

        }
        return res;
      });
  }

  if (type === 1) {
    return axios
      .post(`${END_POINT.BASE_URL}${url}`, data, {
       headers: header
      })
      .then(res => {
        if(res.data.message=="Unauthorized access "){
          window.location = END_POINT.APP_BASE;
          localStorage.removeItem("dmvUser");

        }
        return res;
      });
  }

  if (type === 3) {
    return axios
      .put(`${END_POINT.BASE_URL}${url}`, data, {
       headers: header
      })
      .then(res => {
        if(res.data.message=="Unauthorized access "){
          window.location = END_POINT.APP_BASE;
          localStorage.removeItem("dmvUser");

        }
        return res;
      });
  }

  if (type === 4) {
    return axios
      .delete(`${END_POINT.BASE_URL}${url}`,{
        params:data,
        headers: header
      })
      .then(res => {
        if(res.data.message=="Unauthorized access "){
          window.location = END_POINT.APP_BASE;
          localStorage.removeItem("dmvUser");

        }
        return res;
      });
  }
};
