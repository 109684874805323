import { combineReducers } from "redux";
import {questionReducer} from "./question-reducer/questionReducer"
import {seminarPaymentReducer} from "./seminar-reducer/seminarPaymentReducer"
import {contentReducer} from "./content-reducer/contentReducer"



 



export const reducers = combineReducers({
  question:questionReducer,
  seminar:seminarPaymentReducer,
  content:contentReducer
});
