import { ACTIONS } from "../../action-constants/Actions"


const ContentFetching=()=>{
    return {
        type: ACTIONS.CONTENT_ACTION.GET_CONTENT.CONTENT_FETCHING,
        payload:{
            isContentFetching:true,
        },
        isSuccess: false,
    }
}
const ContentFetchingFailed=({ result, msg })=>{
    return {
        type: ACTIONS.CONTENT_ACTION.GET_CONTENT.CONTENT_FETCHING_FAILED,
        payload:{
            isContentFetching:false,
            result,
            msg
        },
        isSuccess: false,
    }
}
const ContentFetched=({ result })=>{
    return {
        type: ACTIONS.CONTENT_ACTION.GET_CONTENT.CONTENT_FETCHED,
        payload:{
            isContentFetching:false,
            result:result,
        },
        isSuccess: true,
    }
}
export const Content = {
    ContentFetching,
    ContentFetchingFailed,
    ContentFetched
}