import { _callApi } from "../../../services/baseService";
import { END_POINT } from "../../../constants/apiEndPoints";
import { add } from "../../actions/CommonAction/Add";

export const SignUpApi = form => {
  return (dispatch, getState) => {
    dispatch(add.Adding());
    return _callApi(
      END_POINT.AUTH.SIGNUP.END_POINT,
      END_POINT.AUTH.SIGNUP.METHOD,
      form
    ).then(res => {
      const { message = null, result = null } = res.data;
      if ( Object.keys(result).length === 0) {
        dispatch(add.AddingFailed({result,message}));
        return res.data;
      } else {
        dispatch(add.Added({result}));
        return res.data;
      }
    });
  };
};
