import React, { Component } from "react";
import { Container, Row, Col,Navbar,Nav } from "react-bootstrap";
import {  withRouter } from "react-router-dom";

import { connect } from "react-redux";

const mapDispatchToProps = dispatch => {
    return {
      
    };
  };
  
  const mapStateToProps = state => {
    return {
        signInState:state.signin
    };
  };

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
          
        };
    }

    handleSearch = (values, formikBag)=> {
        let { history } = this.props
        history.push(`/portal/Category/${values.search}`)
    }

    lessonHandler = (values, formikBag)=> {
        let { history } = this.props
        history.push(`/portal/dashboard`)
    }

    logoutHandler = (values, formikBag)=> {
        let { history } = this.props
        localStorage.removeItem("dmvUser");
        window.location.href='/';
        //history.push(``);
    }

    render() {

        return (
            <Navbar className="nav" expand="lg">
            <Navbar.Brand href="#home">
                <img style={{'max-width': '80px'}}  src="https://drivinginstructorlicensecourse.com/static/media/Advantage-Circle-Logo.f1eb15a6.png"/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="flex-row-reverse">
                <Nav >
                    <Nav.Link className="link" href="#home">Home</Nav.Link>
                    <Nav.Link className="link" href="#link">About Us</Nav.Link>
                    <Nav.Link className="link" onClick={this.lessonHandler}>Lesson</Nav.Link>
                    <Nav.Link className="link" onClick={this.logoutHandler} >Logout</Nav.Link>
                </Nav>
                
            </Navbar.Collapse>
            </Navbar>
        );
    }
}

export default Header = withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
