import React, { Component } from 'react'
import {Tab,Nav, Accordion,Card,Modal } from "react-bootstrap";
import { withRouter } from 'react-router';
import { MyVerticallyCenteredModal,LoadingComponent } from '../../commonComponents';
import { connect } from "react-redux";
import { CONSTANTS } from "../../../../constants/constants";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import img52jpg from '../../../../assets/images/52'
import { QuestionApi } from "../../../../store/api-actions/question/questionApi";
import { AnswerApi } from "../../../../store/api-actions/answer/AnswerApi";
import { COMMON_FUNCTION } from "../../../../constants/commonFunction";
import { END_POINT } from "../../../../constants/apiEndPoints";



const mapDispatchToProps = dispatch => {
  return {
    Question: form => dispatch(QuestionApi(form)),
    Answer: form => dispatch(AnswerApi(form))
  };
};

const mapStateToProps = state => {
  return {
      questionState:state.question
  };
};

class TestContent extends Component {
    constructor(props) {
      super(props);
      this.state = this.initialState;
      this.myRef = React.createRef()
    }

    get initialState() {
      return {
        message: null,
        messageElement: false,
        success: false,
        isSuccessConfirMsg:false,
        isLoader:false,
        answerSheet:[],
        wrongCount:0,
        rightCount:0,
        isShow:false,
        score:0,
        isPass:false
      };
    }

    resetBuilder() {
      this.setState(this.initialState);
    }

    questionHandler=(event)=>{
       let req = {}
       req.seminarId = this.props.match.params.id
       req.qId = event.id
       req.answer = event.target.value
       req.isAnswer = false
       req.rightAnswer = this.state.rightCount
       req.wrongAnswer = this.state.wrongCount
       var wrongCount = this.state.wrongCount
       var rightCount = this.state.rightCount

       let {list}=this.props.questionState
       let ansObj = list.find(ans => (ans._id === req.qId && ans.answer === req.answer))
        
       let sheetArr = this.state.answerSheet
       let obj = sheetArr.find(o => o.qId === req.qId);
       if(obj){
          sheetArr.pop(obj);
          if(ansObj){
            req.isAnswer = true
            rightCount = rightCount+1
            req.rightAnswer = rightCount
            wrongCount = wrongCount-1
            req.wrongAnswer = wrongCount
          }else{
            req.isAnswer = false
            rightCount = rightCount-1
            req.rightAnswer = rightCount
            wrongCount = wrongCount+1
            req.wrongAnswer = wrongCount
          }
       }else{
        if(ansObj){
          req.isAnswer = true
          rightCount = rightCount+1
          req.rightAnswer = rightCount
        }else{
          wrongCount = wrongCount+1
          req.wrongAnswer = wrongCount
        }
       }



       console.log("request======",obj,sheetArr)
       sheetArr.push(req);
       this.setState({answerSheet:sheetArr,rightCount:rightCount,wrongCount:wrongCount})
    }

    handleSubmit=()=>{
      let req = {}
      let {list}=this.props.questionState;
      var isPass = false;
      let totalQuestion = list.length
      let score = (this.state.rightCount*100)/list.length
      if(score>=75){
        isPass = true
      }
      req.answerSheet = this.state.answerSheet
      req.seminarId = this.props.match.params.id
      req.isPass = isPass
      this.setState({isShow:true,isPass:isPass,score:score})
      this.props.Answer(req).then((result)=>{

      })
    }

    tryAgainHandler=()=>{
      this.resetBuilder();
      this.myFormRef.reset();
      COMMON_FUNCTION.handleScrollToStats();
      
    }

    backToLessonHandler=()=>{
      this.props.history.push('/portal/seminar/'+this.props.match.params.id)
    }

    continueToPreceedHandler=()=>{
      this.props.history.push('/loginWithOtp.html')
    }

    componentDidMount(){
      this.setState({isLoading:true});
      let seminar = this.props.match.params.id
      this.props.Question({seminarId:seminar}).then((response)=>{
        this.setState({isLoading:false});
      })
    }


  render() {
    var loader = ""
    if(this.state.isLoading){
        loader = <LoadingComponent/>
    }
    let {list}=this.props.questionState
    return (
       
            <div ref={this.myRef} class="container">
              {loader}
               <MyVerticallyCenteredModal
                show={this.state.isShow}
                isPass={this.state.isPass}
                score={this.state.score}
                questionCorrect={this.state.rightCount}
                totalQuestion={list.length}
                tryAgain={this.tryAgainHandler}
                backToLesson={this.backToLessonHandler}
                continue={this.continueToPreceedHandler}
               />
            <h2>{(this.props.match.params.id).replace('seminar', 'Seminar')}</h2>
            <hr/>
            <div>
              <div class="row">
                <div class="col-md-12 text-center" >
                  <img src={img52jpg} />
                </div>
                <div>&nbsp;</div>
                <div class="col-md-12 " >
                  <p class="test-head" >Select the appropriate answer for each question. When you are done, click the button to submit your answers and find out your test score, if you receive a score below 70% you will be able to reread the information you missed. You will be then be required to take a new test until you pass.</p>
                </div>
              </div>
              <form ref={(el) => this.myFormRef = el}>
              {
                list.map((item,index)=>(
                  <>
                  <div className="clearfix">&nbsp;</div>
                  {index==0 && this.props.match.params.id=="seminar-3"?(<><h5 className="section-color">Section-1</h5><hr/></>):("")}
                  {index==10 && this.props.match.params.id=="seminar-3"?(<><h5 className="section-color">Section-2</h5><hr/></>):("")}
                  <div class="row ">
                    <div class="col-md-12">
                      <p><span>{(index+1)})</span> {item.title}</p>
                      {item.image?(
                      <div>
                        <img class="img-question" src={END_POINT.IMG_BASE+item.image} />
                      </div>
                      ):""}
                    </div>
                    <div class="col-md-12 option">
                      {item.option1!='3'?(
                      <div class="custom-control custom-radio">
                        <input type="radio" id={item._id+item.option1} onChange={(event)=>{
                          event.id = item._id
                          this.questionHandler(event)}} value={item.option1} name={"q"+(index+1)} class="custom-control-input"/>
                        <label class="custom-control-label" for={item._id+item.option1}>{item.option1}</label>
                      </div>
                      ):(
                        ""
                      )}
                      {item.option2!='0'?(
                      <div class="custom-control custom-radio">
                        <input type="radio" id={item._id+item.option2} onChange={(event)=>{
                          event.id = item._id
                          this.questionHandler(event)}} value={item.option2} name={"q"+(index+1)} class="custom-control-input"/>
                        <label class="custom-control-label" for={item._id+item.option2}>{item.option2}</label>
                      </div>
                      ):(
                        ""
                      )}
                      {item.option3!='0'?(
                      <div class="custom-control custom-radio">
                        <input type="radio" id={item._id+item.option3} value={item.option3} onChange={(event)=>{
                          event.id = item._id
                          this.questionHandler(event)}} name={"q"+(index+1)} class="custom-control-input"/>
                        <label class="custom-control-label" for={item._id+item.option3}>{item.option3}</label>
                      </div>
                      ):(
                        ""
                      )}
                      {item.option4!='0'?(
                        <div class="custom-control custom-radio">
                        <input type="radio" id={item._id+item.option4} value={item.option4} onChange={(event)=>{
                          event.id = item._id
                          this.questionHandler(event)}} name={"q"+(index+1)} class="custom-control-input"/>
                        <label class="custom-control-label" for={item._id+item.option4}>{item.option4}</label>
                      </div>
                      ):(
                        ""
                      )}
                      
                    </div>
                  </div>
                  </>
                ))
              }
              </form>
              <div className="row">
                <div className="col-md-12 text-center padding-10">
                  <button className="btn btn-primary " onClick={this.handleSubmit}>submit</button>
                </div>
              </div>
            </div>
          </div>
    )
  }
}

export default TestContent  = withRouter(connect(mapStateToProps, mapDispatchToProps)(TestContent));
