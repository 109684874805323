import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import {Tab,Nav, Accordion,Card } from "react-bootstrap";
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { CONSTANTS } from "../../../../constants/constants";
import { COMMON_FUNCTION } from "../../../../constants/commonFunction";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Timer from 'react-compound-timer'
import { LessonTimeApi } from "../../../../store/api-actions/lessonTime/LessonTimeApi";
import { ContentApi } from "../../../../store/api-actions/contentApi/ContentApi";
import { LessonModal,LoadingComponent } from '../../commonComponents';





const mapDispatchToProps = dispatch => {
  return {
    LessonTime: form => dispatch(LessonTimeApi(form)),
    Content: form => dispatch(ContentApi(form))
  };
};

const mapStateToProps = state => {
  return {
    contentState:state.content
  };
};

class CourseContent extends Component {
   
  constructor(props){
    super(props)
    this.state = {
      time: 0,
      isOn: false,
      start: 0,
      isTimer:false,
      activeKey:null,
      isModal:false,
      modalIndex:0,
      isLoader:false
    }
    
  }
  startTestHandler = ()=>{
    this.props.history.push('/portal/test/'+this.props.match.params.id);
  }

  startTimer=()=> {
    this.setState({
      isOn: true,
      time: this.state.time,
      start: Date.now() - this.state.time
    })
    this.timer = setInterval(() => this.setState({
      time: Date.now() - this.state.start
    }), 1);
  }
  stopTimer =()=> {
    this.setState({isOn: false})
    clearInterval(this.timer)
  }
  resetTimer =()=> {
    this.setState({time: 0, isOn: false})
  }

  componentWillReceiveProps(newProps){
    let { activeKey} = newProps;
    if(this.state.activeKey != activeKey){
        this.timerHandler(activeKey)
    }
  }


  timerHandler = (activeKey)=>{
    
    let {list}=this.props.contentState
    let idAct = list.find(ans => (ans.seminarId === this.props.match.params.id && ans.isRead === true))
    console.log("=+++++====++++===",idAct,activeKey)
    if(idAct){
      if(idAct && idAct.index==activeKey){
        this.setState({isTimer:true})
        console.log("hghgjhjhhvhvhjvjvjvv",activeKey)
      }else{
        console.log("falsee value",activeKey)
          this.setState({isTimer:false})
      }
    }
    
  }
  


  componentDidMount(){
    this.setState({isTimer:true})
  }

  lessonHandler = (item)=>{
    let req = {}
    req.seminarId = item.seminarId
    req.lessonId = item.id
    console.log("modal indes======",item.index)
    this.setState({modalIndex:parseInt(item.index)+1})
    this.props.LessonTime(req).then((result)=>{
      this.props.callbackLoader(true)
      this.props.Content(req).then((response)=>{
        this.props.callbackLoader(false)
        this.setState({isModal:true})
        console.log("yyyyyy")
      })
    })
  }

  close=()=>{
    this.setState({isModal:false})
  }

  render() {
    var loader = ""
    if(this.state.isLoading){
        loader = <LoadingComponent/>
    }
    let seminar = this.props.match.params.id
    let semarr = {"seminar-1":1,"seminar-2":2,"seminar-3":3}
    let {list}=this.props.contentState
    let idAct = list.find(ans => (ans.seminarId === seminar && ans.isRead === true))

    // if(!idAct){
    //   this.props.history.push('/portal/dashboard/');
    // }

    return (
      <>
        <LessonModal
          show={this.state.isModal}
          continue={this.close}
          next={()=>{
            this.setState({isModal:false})
            let key = list[this.state.modalIndex].index
            console.log("=============99999999999",key)
            this.props.parentCallback(key);
          }}
          startest = {()=>{
            this.startTestHandler();
          }}
          isLesson={list.length-1==this.state.modalIndex?1:0}
        />
        <Tab.Content>
          
          
            {
              list.map((item,index)=>(
                <Tab.Pane eventKey={item.index}>
                  <div> 
                    <h2>{item.index}
                    <smal className="pull-right min-time" > 
                      <span>Minimum Time :  {item.lessonTime}</span><br/>
                      <span className="time-consume"> {
                        idAct && (item.id==idAct.id)?
                        (
                          <Timer
                                initialTime={COMMON_FUNCTION.timeTOMili(item.lessonTime)}
                                direction="backward"
                                startImmediately={this.state.isTimer}
                                lastUnit="h"
                                checkpoints={[
                                  {
                                    time:0,
                                    callback:()=>{
                                      item.index = index;
                                      this.lessonHandler(item)
                                    }
                                  }
                                ]
                                  
                                }
                            >
                          {({ start, resume, pause, stop, reset, getTimerState, getTime }) => (
                              <React.Fragment >
                                  <Timer.Hours />:
                                  <Timer.Minutes />:
                                  <Timer.Seconds />
                                  {
                                  this.state.isTimer?
                                  resume():pause()}
                              </React.Fragment>
                          )}
                          </Timer>
                        ):
                        ("")
                      }
                      </span>
                    </smal>
                    </h2>
                    <hr/> 
                    <div>
                    {ReactHtmlParser(item.content)}
                    </div>
                  </div>  
                  
                  {
                  (item.index!="Introduction")?
                  (
                    ""
                  ):
                  (
                    <div className="row">
                    <div className="col-md-12">
                      <div  >
                      <ul className="intro-index">
                      {
                        list.map((item1,index1)=>(
                        <>
                          {(index1==0 && seminar=='seminar-3')?(
                          <li><span>Section-1</span></li>
                          ):(
                            (index1==8 && seminar=='seminar-3')?(
                              <li><span>Section-2</span></li>
                              ):(
                                ""
                              )
                          )}
                         
                        <li><strong>{semarr[this.props.match.params.id]}.{index1}. &nbsp;</strong>  {item1.index}</li>
                         </>
                        ))
                      }
                      </ul>
                      </div>
                    </div>
                  </div>
                  )
                }
                  
                  <div className="row ">
                    <div className="col-md-12 padding-10">
                    <button className="btn btn-info btn-sm pull-left" disabled={index==0?true:false} onClick={()=>{
                            let key = list[index-1].index
                            this.props.parentCallback(key);
                    }}><i className="fa fa-angle-double-left"></i> Previous</button>
                    {
                      index==(list.length-1)?(
                        <button className="btn btn-info btn-sm pull-right" onClick={()=>{
                          this.startTestHandler();
                      }}><i className="fa fa-play-circle"></i> Start Test </button>
                      ):(
                        <button disabled={item.isRead} className="btn btn-info btn-sm pull-right" onClick={()=>{
                          let key = list[index+1].index
                          this.props.parentCallback(key);
                        }}>Next <i className="fa fa-angle-double-right"></i></button>
                      )
                    }
                    
                    </div>
                  </div>
                </Tab.Pane>
              ))
            }
        </Tab.Content>
        </>
    )
  }
}

export default CourseContent  = withRouter(connect(mapStateToProps, mapDispatchToProps)(CourseContent));
