import React, { Component } from "react";
import { Switch, Route,Link} from "react-router-dom";
import TestIndex from "../TestIndex/TestIndex"
import TestContent from "../TestContent/TestContent"

export default class TestWrapper extends Component {
    render() {

        return (
            <div className="seminar-div">
                <div className="row row-padding">
                    <div className="col-md-3 col-3-border">
                        <TestIndex/>
                    </div>
                    <div className="col-md-9 min-height">
                        <TestContent/> 
                    </div>
                </div>
            </div>
        );
    }
}
