import React, { Component } from 'react'
// import { Accordion, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import {Nav, Accordion,Card} from "react-bootstrap";
import { connect } from "react-redux";
import { CONSTANTS } from "../../../../constants/constants";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { withRouter } from 'react-router';


const mapDispatchToProps = dispatch => {
  return {
   
  };
};

const mapStateToProps = state => {
  return {
      
  };
};

class TestIndex extends Component {
  state = { activeIndex: 0 }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  render() {
    const { activeIndex } = this.state
    let seminar = this.props.match.params.id
    let content = CONSTANTS.SEMINAR[seminar]
    return (
      <div className="sidebar-index back-lesson">
         <Link to={"/portal/seminar/"+this.props.match.params.id} >Back To Lesson</Link>
      </div>
    )
  }
}

export default TestIndex  = withRouter(connect(mapStateToProps, mapDispatchToProps)(TestIndex));
