import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Map, GoogleApiWrapper } from 'google-maps-react';
class Googlemap extends Component {

    render() {
         
        const mapStyles = {
            width: '100%',
            height: '100%',
          };

        return (
            
            <div className="googlemap-area">
                <Container>
                    <div className="googlemap-area-inner">
                        <Row>
                            <Col md="12" lg="12">
                            <Map
                                google={this.props.google}
                                zoom={8}
                                style={mapStyles}
                                initialCenter={{ lat: 28.556760, lng: 77.326400}}
                                />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        );
    }
}

export default Googlemap
