import * as Yup from 'yup';

export const VALIDATORS={
     isImage:(name)=>{
         let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
         if(allowedExtensions.exec(name)){
         return true;
         }else{
            return false;
         }
      },
      SIGNUP:Yup.object().shape({
         userName: Yup.string().required('User Name is required').min(1, 'User Name is too short - should be 1 chars minimum.')
         .max(70, 'Too Long!'),
         password: Yup.string().required('Password is required').min(8, 'Password is too short - should be 8 chars minimum.')
         .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
         firstName: Yup.string().required('First Name is required').min(1, 'First Name is too short - should be 1 chars minimum.')
         .max(70, 'Too Long!'),
         lastName: Yup.string().required('Last Name is required').min(1, 'Last Name is too short - should be 1 chars minimum.')
         .max(70, 'Too Long!'),
         address1: Yup.string().required('Address is required').min(1, 'Address is too short - should be 1 chars minimum.')
         .max(70, 'Too Long!'),
         city: Yup.string().required('City is required').min(1, 'City is too short - should be 1 chars minimum.')
         .max(70, 'Too Long!'),
         state: Yup.string().required('State is required').min(1, 'State is too short - should be 1 chars minimum.')
         .max(70, 'Too Long!'),
         zipCode: Yup.number().required('Zip Code  is required').min(1, 'Zip Code is too short - should be 1 chars minimum.')
         .max(5000000, 'Too Long!'),
         phoneNumber: Yup.string().required('Phone Number is required').min(7, 'Phone Number is too short - should be greater than 7 chars minimum.')
         .max(12, 'Too Long!'),
         email: Yup.string().required('Email is required').email('Please Fill Valid Email Address.'),
         dob: Yup.date().required('Dob is required')
         
      }),
      PAYMENT:Yup.object().shape({
         firstName: Yup.string().required('First Name is required').min(1, 'First Name is too short - should be 1 chars minimum.')
         .max(70, 'Too Long!'),
         lastName: Yup.string().required('Last Name is required').min(1, 'Last Name is too short - should be 1 chars minimum.')
         .max(70, 'Too Long!'),
         address1: Yup.string().required('Address is required').min(1, 'Address is too short - should be 1 chars minimum.')
         .max(70, 'Too Long!'),
         city: Yup.string().required('City is required').min(1, 'City is too short - should be 1 chars minimum.')
         .max(70, 'Too Long!'),
         state: Yup.string().required('State is required').min(1, 'State is too short - should be 1 chars minimum.')
         .max(70, 'Too Long!'),
         zipCode: Yup.number().required('Zip Code  is required').min(1, 'Zip Code is too short - should be 1 chars minimum.')
         .max(5000000, 'Too Long!'),
         phoneNumber: Yup.string().required('Phone Number is required').min(7, 'Phone Number is too short - should be greater than 7 chars minimum.')
         .max(12, 'Too Long!'),
         email: Yup.string().required('Email is required').email('Please Fill Valid Email Address.'),
         // ccNumber: Yup.string().required('Card Number is required'),
         // expirationMonth: Yup.string().required('Expiration Month required').min(2, 'Expiration Month is too short - should be 2 chars minimum.')
         // .max(2, 'Too Long!'),
         // expirationYear: Yup.string().required('Expiration Year is required').min(2, 'Expiration Year is too short - should be 2 chars minimum.')
         // .max(2, 'Too Long!'),
         // cvvNumber: Yup.string().required('Cvv is required').min(3, 'CVV Number is too short - should be 1 chars minimum.')
         // .max(7, 'Too Long!'),
      }),
      FORGET:Yup.object().shape({
         userName: Yup.string().required('User Name is required').min(1, 'User Name is too short - should be 1 chars minimum.')
         .max(70, 'Too Long!'),
      }),
      SIGNIN:Yup.object().shape({
         userName: Yup.string().required('User Name is required').min(1, 'User Name is too short - should be 1 chars minimum.')
         .max(70, 'Too Long!'),
         password: Yup.string().required('Password is required').min(8, 'Password is too short - should be 8 chars minimum.')
         .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.')
         }),
      OTP:Yup.object().shape({
         otp: Yup.number().required('otp is required').min(4, 'otp is too short - should be 4 chars minimum.')
         .max(50000, 'Too Long!')
      }),
      ADDTOCART:Yup.object().shape({
         fromDate: Yup.date().required('From Date is required'),
         toDate: Yup.date().required('To Date is required')
      }),
      CHANGEPASSWORD:Yup.object().shape({
         currentPassword: Yup.string().required('Password is required').min(8, 'Password is too short - should be 8 chars minimum.')
         .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
         newPassword: Yup.string().required('Password is required').min(8, 'Password is too short - should be 8 chars minimum.')
         .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
         confirmPassword: Yup.string().required('Confirm Password is required.') 
         .min(8, 'Password is too short - should be 8 chars minimum.')
         .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.')
         .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      }),
      POSTPRODUCT:Yup.object().shape({
         title: Yup.string().required('Title  is required').min(1, 'Country Code is too short - should be 1 chars minimum.')
         .max(70, 'Too Long!'),
         categoryId: Yup.string().required('Category Id is required'),
         subCategoryId: Yup.string().required('Sub Category Id is required'),
         length :Yup.string().required('Length is required'),
         size :Yup.string().required('Size is required'),
         price :Yup.number().required('Price is required'),
         securityMoney :Yup.number(),
         penaltyAmount :Yup.number()
         
      }),
      MYPROFILE:Yup.object().shape({
         name: Yup.string().required('Name is Required').min(1, 'Name is too short - should be 1 chars minimum.')
         .max(70, 'Too Long!')
      }),     
}
