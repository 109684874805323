import React, { useEffect } from 'react';
import Portal from "./view/Layout/portal/Portal";
import { Login, ForgotPassword, MainAuth } from './view/components/authComponents'
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";


function App() {

    // (function (history) {
    //     var pushState = history.pushState;
    //     history.pushState = function (state) {
    //         if (typeof history.pushstate == "function") {
    //             history.onpushstate({ state: state });
    //         }

    //         console.log('push state');

    //         // whatever else you want to do
    //         // maybe call onhashchange e.handler
    //         return pushState.apply(history, arguments);
    //     }
    // })(window.history);

    
    // useEffect(()=>{
    //     console.log('effect');
    //     window.onpopstate = window.history.onpushstate =  (e)=> {
    //         console.log('on pop state ==>>',e);
    //     };
    //     return function(){
    //         window.removeEventListener('popstate',(e)=> {
    //             debugger;
    //             console.log('on pop state ==>>',e);
    //             debugger;
    //         }); 
    //     }
    // })

    return (
        <BrowserRouter basename="">
            <Switch>
                {/* <Portal/> */}
                <Route
                    exact={true}
                    path="/"
                    render={props => {
                        let user = JSON.parse(localStorage.getItem("dmvUser"));
                        if (user && user.accessToken) {
                            return <Redirect from="/" to="/portal/dashboard" />;
                        } else {
                            return <Redirect from="/" to="/auth" />;
                        }
                    }
                    }
                />

                {/* <Route path="/"   component={MainAuth} /> */}
                {/* <Route path="/login"   component={Portal} /> */}
                <Route path="/auth" component={MainAuth} />
                <Route path="/portal" component={Portal} />
            </Switch>
        </BrowserRouter>
    );
}

export default App;
