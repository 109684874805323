import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { VALIDATORS } from "../../../../constants/validation-schemas";
import {InputBox,LoadingComponent} from '../../commonComponents/index'
import { SignInApi } from "../../../../store/api-actions/auth/signInApi";
import { MyProfileDetailsApi } from "../../../../store/api-actions/auth/myprofileDetailsApi";
import {MESSAGE} from '../../../../constants/confirmMessage';
import {swal} from '../../../../constants/confirm-alert';



const mapDispatchToProps = dispatch => {
  return {
    signIn: form => dispatch(SignInApi(form))
  };
};

const mapStateToProps = state => {
  return {
      
  };
};

class Login extends Component {

    constructor(props) {
      super(props);
      this.state = {
        formFields: {},
        errors: {},
        message: null,
        messageElement: false,
        success: false,
        file:"",
        s3file:"",
        isSuccessConfirMsg:false,
        isLoader:false
      };
    }

    handleSignIn = (values, formikBag)=> {
      this.setState({isLoading:true});
      this.props.signIn(values).then(res=>{
        if(res.status && Object.keys(res.result).length  > 0){
          let { history } = this.props
          localStorage.setItem('dmvUser', JSON.stringify(res.result))
          this.setState({isLoading:false});
          history.push(`/`)
        }else{
            this.setState({isFailedConfirMsg:true,isLoading:false,message:res.message,title:MESSAGE.FAILED_TITLE});
        }
      })
    }

    render() {
        var loader = ""
        var conm = ""
         

        var obj = {
          title:this.state.title,
          onConfirm:()=>{
          this.setState({isFailedConfirMsg:false,isSuccessConfirMsg:false});
          this.props.history.push(`/auth/login`)
          },
          subtitle:this.state.message
        }

        if(this.state.isFailedConfirMsg){
            conm = swal.failed(obj);
        }
        
        if(this.state.isSuccessConfirMsg){
            conm = swal.success(obj);
        }
        if(this.state.isLoading){
            loader = <LoadingComponent/>
        }

        return (            
          <>
          {loader}
          {conm}
          <Formik
                initialValues={{
                  userName:'',
                  password:''
                }}
                onSubmit={this.handleSignIn}
                validationSchema={VALIDATORS.SIGNIN}
                render={({ handleSubmit, errors, touched, values, handleChange,setFieldValue })=>(
                  <form onSubmit={handleSubmit}>
                      <div class="tab-content" id="myTabContent">
                              <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                  <h3 class="register-heading">Login</h3>
                                  <div class="row register-form justify-content-center">
                                          <InputBox
                                              formWrapClass = "form-group col-md-8 max-auto"
                                              inputClass="form-control"
                                              type="text"
                                              placeholder="* User Name"
                                              name = "userName"
                                              handleChange={handleChange}
                                              value={values.userName}
                                              errorClass = "text-danger"
                                              errorText = { touched.userName && errors.userName }
                                          />  
                                          <InputBox
                                              formWrapClass = "form-group col-md-8 max-auto"
                                              inputClass="form-control"
                                              type="password"
                                              placeholder="* Password"
                                              name = "password"
                                              handleChange={handleChange}
                                              value={values.password}
                                              errorClass = "text-danger"
                                              errorText = { touched.password && errors.password }
                                          />  
                                          <div className="col-md-8 max-auto">
                                              <Link className="btn btn-link" to="/auth/customer-information/">Sign Up</Link>
                                              <button type="submit" className="btnRegister" >Login</button>
                                          </div> 
                                       
                                  </div>
                              </div>
                              
                          </div>
                  </form>
                  )}
                  />
      </>  
        );
    }
}

export default Login  = connect(mapStateToProps, mapDispatchToProps)(Login);
