import React, { Component } from "react";
import { Switch, Route,Link} from "react-router-dom";
import CourseIndex from "../CourseIndex/CourseIndex"
import CourseContent from "../CourseContent/CourseContent"
import {Tab,Nav, Accordion,Card } from "react-bootstrap";
import {COMMON_FUNCTION} from "../../../../constants/commonFunction"
import { ContentApi } from "../../../../store/api-actions/contentApi/ContentApi";
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import {InputBox,LoadingComponent} from '../../commonComponents/index'


const mapDispatchToProps = dispatch => {
    return {
        Content: form => dispatch(ContentApi(form))
    };
  };
  
  const mapStateToProps = state => {
    return {
        contentState:state.content
    };
  };

 class CourseWrapper extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeKey:"Introduction",
            formFields: {},
            errors: {},
            message: null,
            messageElement: false,
            success: false,
            file:"",
            s3file:"",
            isSuccessConfirMsg:false,
            isLoader:false
        };
    }

    calbackHandler=(activeKey)=>{
        console.log("[][][][][]][[",this.myRef)
        COMMON_FUNCTION.handleScrollToStats()
        
        this.setState({activeKey:activeKey})
    }

    contentHandler=(event)=>{
        this.setState({isLoading:true});
        let seminar = this.props.match.params.id
        this.props.Content({seminarId:seminar}).then((response)=>{
            let {list}=this.props.contentState
            let idAct = list.find(ans => (ans.seminarId === this.props.match.params.id && ans.isRead === true))
            if(idAct){
                this.setState({activeKey:idAct.index}) 
            } 
            this.setState({isLoading:false});
        })
    }
    componentDidMount(){
     this.contentHandler() 
     
       
    } 

    callbackLoader = (e)=>{
        this.setState({isLoading:e})
    }

    render() {
        var loader = ""
        if(this.state.isLoading){
            loader = <LoadingComponent/>
        }
        let {list}=this.props.contentState
         
          
        
        return (
            
            <>
            {loader}
            <Tab.Container id="left-tabs-example" activeKey={this.state.activeKey}>
                <div className="seminar-div">
                    <div className="row row-padding">
                        <div className="col-md-3 col-3-border">
                            <CourseIndex item = {list} parentCallback={this.calbackHandler} />
                        </div>
                        <div className="col-md-9 min-height">
                            <CourseContent activeKey={this.state.activeKey} callbackLoader={this.callbackLoader} item = {list} parentCallback={this.calbackHandler}/> 
                        </div>
                        
                    </div>
                </div>
            </Tab.Container>
            </>
        );
    }
}
export default CourseWrapper= withRouter(connect(mapStateToProps, mapDispatchToProps)(CourseWrapper));